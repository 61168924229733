import React, {useEffect, useState} from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout';
import {adDisplayDelay, delayMultiplicationFactor, setStreamData} from '../utils/articleUtil';
import {Basic, Facebook, Twitter} from '../components/SEO';
import mixpanel from '../constants/mixpanel';
import {isMobile} from 'react-device-detect';
import {categoryPageAds} from '../utils/adUnits';
import {metadata} from '../../config';
import {addTimeout} from '../utils/timeManager';
import SeeMore from '../components/buttons/SeeMore';
import {
  ArticleList,
  ArticlesComplexLayout,
  ArticlesHorizontalLayout,
  CategoryHeader,
  track
} from '@cg-squad/ui-components';
import Helmet from 'react-helmet';

const CategoryPage = props => {
  setStreamData({
    category: props.pageContext.title,
    subCategory: undefined,
    article: undefined,
    author: undefined
  });

  const seo = {
    title: props.pageContext.title,
    description: `Discover all of our ${props.pageContext.title} articles, written by our knowledgeable team of writers.`
  };
  const url = `${metadata.url}/${props.pageContext.slug}`;
  const allArticles = [...props.data.pillarArticles.nodes];

  props.data.subCategoryArticles.nodes.forEach(article => {
    if (!props.data.pillarArticles.nodes.find(a => a.id === article.id)) {
      allArticles.push(article);
    }
  });

  const articleList = allArticles.slice(13);

  const [displayedSubCategoryArticles, setDisplayedSubCategoryArticles] = useState(articleList.slice(0, 10));

  useEffect(() => {
    track(mixpanel.MIXPANEL_PAGE_VIEW.CATEGORY);
    addTimeout(categoryPageAds, adDisplayDelay() * delayMultiplicationFactor(isMobile), [
      {key: 'category', value: props.pageContext.slug}
    ]);
  }, []);

  const seeMore = () => {
    setDisplayedSubCategoryArticles(articleList.slice(0, displayedSubCategoryArticles.length + 10));
  };

  return (
    <Layout relativePath={props.path?.replace('/', '')} path={props.location.pathname}>
      <Basic seo={seo}/>
      <Twitter seo={seo} url={url}/>
      <Facebook seo={seo} url={url}/>
      <Helmet>
        <link rel="canonical" href={url}/>
      </Helmet>
      <main className="wrapper font-arial leading-snug" data-datocms-noindex>
        <CategoryHeader category={{title: props.pageContext.title, slug: props.pageContext.slug}}/>
        <ArticlesComplexLayout articles={allArticles.slice(0, 9)}/>
        <ArticlesHorizontalLayout articles={allArticles.slice(9, 13)}/>
        <div className="ad-container my-4">
          <div className="advertisement-text">Advertisement</div>
          <div id="pt_category_incontent1"/>
        </div>
        <ArticleList articles={displayedSubCategoryArticles}/>
        {displayedSubCategoryArticles.length < articleList.length &&
          <SeeMore onSeeMoreClick={seeMore}/>}
        <div className="ad-container my-4">
          <div className="advertisement-text">Advertisement</div>
          <div id="pt_category_incontent2"/>
        </div>
      </main>
    </Layout>
  );
};

export const query = graphql`
    query ArticlesCategoryWise($subCategories: [String]) {
        pillarArticles: allDatoCmsArticle(filter: {category: {elemMatch: {originalId: {in: $subCategories}}}, website: {elemMatch: {name: {eq: "pt"}}}, isCategoryPinned: {eq: true}}
            sort: {publishedAt: DESC}
        ) {
            nodes {
                ...ArticleFieldsPlugin
            }
        }
        subCategoryArticles: allDatoCmsArticle(filter: {category: {elemMatch: {originalId: {in: $subCategories}}}, website: {elemMatch: {name: {eq: "pt"}}}}
            sort: {publishedAt: DESC}
            limit: 100
        ) {
            nodes {
                ...ArticleFieldsPlugin
            }
        }
    }
`;

export default CategoryPage;
